<template>
  <div>
    <div v-if="!wechat">正在跳转支付收银台...</div>
    <img v-else src="@/assets/images/mt/paytip.jpg" class="img" />
  </div>
</template>
<script>
import Tools from "@/tool";
export default {
  name: "PayBefore",
  components: {},
  data() {
    return {

    };
  },
  computed: {
    wechat() {
      return Tools.isWechat();
    },
  },
  mounted() {
    if (!this.wechat) {
      window.location.href = this.$route.query.payUrl;
    }
  },
};
</script>

<style scoped>
.img {
  width: 100%;
  object-fit: contain;
}
</style>